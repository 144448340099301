
import { defineComponent, reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { required, email, mustBeTrue, phone } from '@/customValidators'
import { useStore } from 'vuex'

export default defineComponent({
  setup: () => {
    const store = useStore()
    store.commit('setVuelidateExternalResults', { registerForm: { } })
    return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
  },
  data: function () {
    return {
      contactForm: {
        email: '',
        phone: '',
        topic: '',
        consent: false
      }
    }
  },
  validations: function () {
    return {
      contactForm: {
        email: { required, email },
        phone: { required, phone },
        topic: { required },
        consent: { mustBeTrue }
      }
    }
  },
  methods: {
    textAreaExtend () {
      const messageArea: any = this.$refs.messageArea
      if (messageArea) {
        messageArea.parentNode.dataset.replicatedValue = messageArea.value
      }
    },
    async submitForm () {
      this.v$.contactForm.$validate()
      if (!this.v$.contactForm.$pending && this.v$.contactForm.$error) return
      await this.$store.dispatch('sendContactForm', { form: this.contactForm })
    }
  },
  components: {
    FieldWrapper
  }
})
